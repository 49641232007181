var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "adjustDetail wd-content" },
    [
      _c("h3", { staticClass: "title" }, [
        _vm._v(
          " " +
            _vm._s(
              `${
                _vm.page_type == "add"
                  ? "添加"
                  : _vm.page_type == "edit"
                  ? "编辑"
                  : "查看"
              }`
            ) +
            "动态 "
        ),
      ]),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "wd-zoom",
          attrs: { "label-width": "150px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "上传图片或视频:", required: "" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.radio,
                    callback: function ($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio",
                  },
                },
                [_vm._v(" 图片 ")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.radio,
                    callback: function ($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio",
                  },
                },
                [_vm._v(" 视频 ")]
              ),
              _vm.radio === 1
                ? _c("cutImgUp", {
                    ref: "cutImgUp",
                    attrs: {
                      url: _vm.imageUrls,
                      "auto-crop-width": 450,
                      "auto-crop-height": 450,
                      fixed: true,
                      "max-num": 9,
                    },
                    on: { change: _vm.changeImage },
                  })
                : _vm._e(),
              _vm.radio === 2
                ? _c("UploadVideoOss", {
                    attrs: { url: _vm.videoUrl },
                    on: { success: _vm.videoSuccess },
                  })
                : _vm._e(),
              _c(
                "p",
                {
                  staticClass: "el-upload__tip",
                  staticStyle: { color: "#FF4949", "font-size": "12px" },
                },
                [_vm._v(" 仅支持jpg、jpeg、png格式的图片和mp4格式的视频 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "动态类型:", required: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "240px" },
                  attrs: {
                    filterable: "",
                    placeholder: "请选择",
                    size: "small",
                  },
                  model: {
                    value: _vm.showStatus,
                    callback: function ($$v) {
                      _vm.showStatus = $$v
                    },
                    expression: "showStatus",
                  },
                },
                _vm._l(_vm.statusList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发布内容" } },
            [
              _c("el-input", {
                staticClass: "input-w300",
                attrs: { type: "textarea", maxlength: "150", rows: "6" },
                model: {
                  value: _vm.description,
                  callback: function ($$v) {
                    _vm.description = $$v
                  },
                  expression: "description",
                },
              }),
              _c("span", [
                _vm._v(" 还能输入 "),
                _c("em", { staticClass: "red" }, [
                  _vm._v(" " + _vm._s(150 - _vm.description.length) + " "),
                ]),
                _vm._v(" 个字 "),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "关联商品" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "240px" },
                  attrs: {
                    filterable: "",
                    placeholder: "请选择",
                    size: "small",
                    loading: _vm.loading,
                  },
                  model: {
                    value: _vm.associatedGood,
                    callback: function ($$v) {
                      _vm.associatedGood = $$v
                    },
                    expression: "associatedGood",
                  },
                },
                _vm._l(_vm.associatedGoodList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "视频上传" } }),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submit },
                },
                [_vm._v(" 发布 ")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v(" 取消 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }