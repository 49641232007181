<template>
  <div class="adjustDetail wd-content">
    <h3 class="title">
      {{ `${page_type == 'add' ? '添加' : page_type == 'edit' ? '编辑' : '查看'}` }}动态
    </h3>
    <el-form
      ref="ruleForm"
      label-width="150px"
      class="wd-zoom"
    >
      <el-form-item
        label="上传图片或视频:"
        required
      >
        <el-radio
          v-model="radio"
          :label="1"
        >
          图片
        </el-radio>
        <el-radio
          v-model="radio"
          :label="2"
        >
          视频
        </el-radio>
        <cutImgUp
          v-if="radio === 1"
          ref="cutImgUp"
          :url="imageUrls"
          :auto-crop-width="450"
          :auto-crop-height="450"
          :fixed="true"
          :max-num="9"
          @change="changeImage"
        />
        <UploadVideoOss
          v-if="radio === 2"
          :url="videoUrl"
          @success="videoSuccess"
        />
        <p
          class="el-upload__tip"
          style="color:#FF4949;font-size:12px;"
        >
          仅支持jpg、jpeg、png格式的图片和mp4格式的视频
        </p>
      </el-form-item>
      <el-form-item
        label="动态类型:"
        required
      >
        <el-select
          v-model="showStatus"
          filterable
          placeholder="请选择"
          size="small"
          style="width:240px;"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="发布内容">
        <el-input
          v-model="description"
          type="textarea"
          maxlength="150"
          class="input-w300"
          rows="6"
        />
        <span>
          还能输入
          <em class="red">
            {{ 150 - description.length }}
          </em>
          个字
        </span>
      </el-form-item>
      <el-form-item label="关联商品">
        <el-select
          v-model="associatedGood"
          filterable
          placeholder="请选择"
          size="small"
          style="width:240px;"
          :loading="loading"
        >
          <el-option
            v-for="item in associatedGoodList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <!-- 视频上传 -->
      <el-form-item label="视频上传">
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="small"
          @click="submit"
        >
          发布
        </el-button>
        <el-button
          size="small"
          @click="cancel"
        >
          取消
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/**
 * 逛农博新增/编辑
 */
// import DetailTitle from '@/components/common/DetailTitle'
import { DYNAMIC_ICON } from '../../config'
import UploadVideoOss from '@/components/common/uploadVideoOss/index.vue'
import cutImgUp from '@/components/common/cutImgUp.vue'

export default {
  name: 'AdjustDetail',
  components: {
    // DetailTitle,
    UploadVideoOss,
    cutImgUp
  },
  data() {
    return {
      id: '', // 展馆id
      associatedGood: '', // 关联商品
      associatedGoodList: [], // 商品下拉
      iconList: DYNAMIC_ICON, // 动态标签
      icon: '', // 标签值
      statusList: [
        { label: '好物', value: 1 },
        { label: '上新', value: 2 }

      ], // 状态
      suppliers: '', // 供应商
      showStatus: '', // 动态类型
      description: '', // 描述
      page_type: '', // 判断是添加还是编辑
      talkId: '',
      loading: false,
      videoUrl: '',
      imageUrls: [],
      radio: 1
    }
  },
  created() {
    this.id = this.$route.query.id
    this.page_type = this.$route.query.type
  },
  mounted() {
    this.page_type === 'edit' && this.getInfo()
    this.getGoodsList()
  },
  methods: {

    // 商品下拉
    getGoodsList() {
      this.loading = true
      this.$axios.get(this.$api.goodsmanage.trade_goods_page, {
        params: {
          currentPage: 1,
          pageSize: 999

        }
      }).then(res => {
        this.associatedGoodList = res.data.records
        this.loading = false
      }).catch(error => {
        console.log(error)
      })
    },

    // 获取详情
    getInfo() {
      let _this = this
      _this.$axios.get(_this.$api.farmer.detail, {
        params: {
          talkId: _this.id
        }
      }).then(res => {
        let r = res || {}
        if (r.code === 0) {
          _this.imageUrls = r.data.imageUrls.split(',')
          _this.talkId = r.data.talkId
          _this.associatedGood = r.data.goodsId
          _this.description = r.data.content
          _this.showStatus = r.data.talkType
          _this.videoUrl = r.data.videoUrl
          _this.radio = r.data.videoUrl ? 2 : 1
        }
      }).catch(error => {
        console.log(error)
      })
    },

    // 提交
    async submit() {
      const {
        associatedGood,
        showStatus,
        description,
        id,
        imageUrls,
        page_type,
        talkId
      } = this
      const _this = this
      this.$axios.post(page_type === 'add' ? this.$api.farmer.add : this.$api.farmer.edit, {
        talkId,
        content: description,
        talkType: showStatus === 1 ? 1 : 2,
        goodsId: associatedGood,
        imageUrls: this.radio === 1 ? imageUrls.join(',') : '',
        videoUrl: this.radio === 2 ? _this.videoUrl : ''
      }).then(res => {
        let r = res || {}
        if (r.code === 0) {
          this.$router.go(-1)
          this.$message.success('提交成功')
        }
      }).catch(error => {
        console.log(error)
      })
    },
    cancel() {
      this.$confirm('编辑未保存, 是否确定取消?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$router.push('/nb/marketingTools/nombo/index')
      }).catch(() => {})
    },
    handleChange(data) {
      data.forEach(item => {
        console.log(item)
        this.imageIds.push(item)
      })
    },
    deleteImg(index) {
      this.imageIds.splice(index, 1)
      console.log(index, this.imageIds)
    },
    videoSuccess(value) {
      this.videoUrl = value
    },
    changeImage(value) {
      console.log(value)
      this.imageUrls = value
    }
  }
}
</script>

<style lang="scss" scoped>
.title{
  font-weight: 600;
  padding: 30px 0;
}
</style>
